const PersonalProjectCardData = [
  {
    title: "Velox Discord Bot",
    text: "Velox Discord Bot is an open-source general purpose discord bot developed in Python. It offers a range of features such as moderation, music, and fun commands. The bot is designed to be easy to use and configure, and it is fully customizable. It is built using the discord.py library.",
    view: "https://github.com/FernandoJVideira/VeloxDiscordBot",
  },
  {
    title: "My Portfolio",
    text: "This portfolio website was built with ReactJS and React Router. It showcases my projects, skills, and contact information as well as a brief introduction about myself. The website is fully responsive and features a clean and modern design.",
    view: "/",
  },
  {
    title: " Velox Web Framework",
    text: "Velox is a lightweight Laravel like framework built from scratch in Go. It is designed to simplify the development of web applications by providing a set of tools and features to streamline the process. The framework includes a router, middleware, and a template engine, among other components.",
    view: "https://github.com/FernandoJVideira/velox",
  },
  {
    title: "LK Results Website",
    text: "LK Results is the landing page for my Marketing/Web Development business. It is built using ReactJS and features a clean and modern design. The website includes information about the services offered as well as a contact form for potential clients.",
    view: "https://lk-results.com",
  },
];
export default PersonalProjectCardData;
